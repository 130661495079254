/**
 * File navigation.js.
 *
 * Handles showing / hiding main nav on scroll
 * and toggling the navigation menu for small screens
 */
(function ($) {
  const positionData = {
    _currentScrollTop: 0,
    _delta: 5,
    _positionChanged: false,
    _prevScrollTop: 0,
    _navBarHeight: $( '#header' ).outerHeight(),
  }

  /**
   * @returns {boolean}
   */
  function positionChanged() {
    return positionData._positionChanged;
  }

  /**
   * @returns {void}
   */
  function setPositionChanged(value) {
    positionData._positionChanged = value;
  }

  /**
   * @returns {int}
   */
  function getPrevScrollTop() {
    return positionData._prevScrollTop;
  }

  /**
   * @returns {void}
   */
  function setPrevScrollTop(value) {
    positionData._prevScrollTop = value;
  }

  /**
   * @returns {int}
   */
  function getDelta() {
    return positionData._delta;
  }

  /**
   * @returns {int}
   */
  function getCurrentScrollTop() {
    return positionData._currentScrollTop;
  }

  /**
   * @returns {void}
   */
  function setCurrentScrollTop(value) {
    positionData._currentScrollTop = value;
  }

  /**
   * @returns {int}
   */
  function getNavBarHeight() {
    return positionData._navBarHeight;
  }

  /**
   * @returns {boolean}
   */
  function scrolledMoreThanDelta() {
    return Math.abs( getPrevScrollTop() - getCurrentScrollTop() ) >= getDelta();
  }

  /**
   * @returns {void}
   */
  function setBodyClasses() {
    if (getCurrentScrollTop() == 0) {
      $( 'body' ).addClass( 'at-top' );
    } else {
      $( 'body' ).removeClass( 'at-top' );
    }

    // If they scrolled down and are past the navbar, add class .hide-nav.
    // This is necessary so you never see what is "behind" the navbar.
    if (scrolledPastNavBar()) {
      $( 'body' ).addClass( 'hide-nav' );
    } else {
      $( 'body' ).removeClass( 'hide-nav' );
    }
  }

  /**
   * @returns {boolean}
   */
  function scrolledPastNavBar() {
    const scrolledPastPreviousTop = getCurrentScrollTop() > getPrevScrollTop();
    const scrolledPastNav         = getCurrentScrollTop() > getNavBarHeight();
    return scrolledPastPreviousTop && scrolledPastNav;
  }

  setInterval(
    function () {
      // Each time this fires, set the current and previous scroll positions
      // in navigationData.  Using these positions, we can determine if the user
      // has scrolled, and if they have, if they scrolled more than the minimum threshold (delta).
      setPrevScrollTop( getCurrentScrollTop() );
      setCurrentScrollTop( $( this ).scrollTop() );
      setPositionChanged( getPrevScrollTop() !== getCurrentScrollTop() );

      if ( ! positionChanged() || ! scrolledMoreThanDelta()) {
        return;
      }

      setBodyClasses();
    },
    250
  );

  // ----------------------------------------------------------
  // Open / close main menu with hamburger
  // ----------------------------------------------------------

  let closeMenu = function () {
    $( 'body' ).removeClass( 'navopen' );
    $( '.menu-toggle' ).attr( 'aria-expanded', 'false' );
    $( '.menu-toggle' ).removeClass( 'is-active' );
  };

  let openMenu = function () {
    $( 'body' ).addClass( 'navopen' );
    $( '.menu-toggle' ).attr( 'aria-expanded', 'true' );
    $( '.menu-toggle' ).addClass( 'is-active' );
  }; // end openMenu

  // ----------------------------------------------------------
  // Doc ready
  // ----------------------------------------------------------
  $( document ).ready(
    function () {

      // ----------------------------------------------------------
      // Main Navigation open / close submenus
      // ----------------------------------------------------------
      if ($( '.main-menu' ).length) {
        $( document ).on(
          'click tap',
          '.main-menu .menu-item-has-children > a',
          function (e) {
            let myLi  = $( this ).parent( '.menu-item-has-children' );
            let mySub = $( this ).next( '.sub-menu' );

            if (myLi.hasClass( 'focus' )) {
              return true;
            } else {
              e.preventDefault();
              $( myLi ).siblings( '.menu-item-has-children' ).removeClass( 'focus' );
              $( myLi ).addClass( 'focus' );
            }
          }
        )
        // hide sub-menu on ESC.
        $( document ).on(
          'keyup',
          function (e) {
            if (e.which == 27) {
              $( '.menu-item-has-children' ).removeClass( 'focus' );
            }
          }
        )
        // hide opened menu by clicking outside it.
        $( document ).on(
          'click tap',
          function (e) {
            if ( ! $( e.target ).closest( '.main-menu' ).length) {
              $( '.menu-item-has-children' ).removeClass( 'focus' );
            }
          }
        )
      } // end if main menu exists.

      $( document ).on(
        'click tap',
        '.menu-toggle',
        function () {
          if ($( this ).hasClass( 'is-active' )) {
            closeMenu();
          } else {
            openMenu();
          }
        }
      );

    }
  ); // end doc.ready.

  // ----------------------------------------------------------
  // Window resize functions
  // ----------------------------------------------------------
  var resizeTimer;
  $( window ).on(
    'resize',
    function (e) {
      clearTimeout( resizeTimer );
      resizeTimer = setTimeout(
        function () {
          // if mobile nav is open, close it.
          closeMenu();
        },
        250
      );
    }
  ); // end resize.

})( window.jQuery );
